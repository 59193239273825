<div class="max-w-xl lg:max-w-4xl mx-auto gap-4 mb-8 mt-4">
  <div
    class="text-2xl lg:text-4xl text-center font-bold text-gfx-primary dark:text-gfx-accent"
  >
    Welcome to Group Fitness X
  </div>
  <div
    class="mt-4 max-w-xs mx-auto border-8 border-gfx-primary rounded-full shadow-xl"
  >
    <img
      class="p-2 mx-auto"
      src="/assets/images/gfx-heartbeat.webp"
      alt="Group Fitness X logo"
    />
  </div>
  <div class="max-w-xl lg:max-w-4xl mx-auto gap-4">
    <div class="p-2">
      <div
        class="mt-8 mx-auto border-2 border-solid border-gfx-primary dark:border-gfx-accent rounded-xl p-2 shadow-lg"
      >
        <div class="text-xl text-center italic text-black dark:text-zinc-300">
          A site dedicated to helping group fitness instructors
          <span class="font-bold">connect with the community</span> and
          <span class="font-bold">find inspiration</span> for their classes.
        </div>
      </div>
    </div>
    <div class="mt-4 mx-auto">
      <a href="https://apps.apple.com/us/app/group-fitness-x/id6723897655">
        <img
          class="p-2 mx-auto"
          src="/assets/images/download-on-the-app-store.svg"
          alt="Download on the App Store"
        />
      </a>
    </div>
    <section id="organize" class="mt-8 mx-auto p-2">
      <p
        class="m-4 text-4xl font-bold text-gfx-primary dark:text-gfx-accent text-center"
      >
        Organize
      </p>
      <div
        class="border-2 border-solid border-gfx-primary dark:border-gfx-accent rounded-xl mx-auto p-4 shadow-lg"
      >
        <p class="text-center dark:text-zinc-300">
          Create your own class plans from scratch, start with a previous
          release, or get inspiration from the community.
        </p>
        <img
          class="dark:hidden w-auto mx-auto p-2 rounded-3xl"
          src="../../assets/images/gfx-organize-light.webp"
        />
        <img
          class="hidden dark:inline w-auto mx-auto p-2 rounded-3xl"
          src="../../assets/images/gfx-organize-dark.webp"
        />
      </div>
    </section>
    <section id="prepare" class="mt-8 mx-auto p-2">
      <p
        class="m-4 text-4xl font-bold text-gfx-primary dark:text-gfx-accent text-center"
      >
        Prepare
      </p>
      <div
        class="border-2 border-solid border-gfx-primary dark:border-gfx-accent rounded-xl mx-auto p-4 shadow-lg"
      >
        <p class="text-center dark:text-zinc-300">
          We understand how you plan your classes. Search by exercises, artists,
          or songs. You can also take inspiration from the community.
        </p>
        <img
          class="dark:hidden w-auto p-2 rounded-3xl"
          src="../../assets/images/gfx-prepare-light.webp"
        />
        <img
          class="hidden dark:inline w-auto p-2 rounded-3xl"
          src="../../assets/images/gfx-prepare-dark.webp"
        />
      </div>
    </section>
    <section id="share" class="mt-8 mx-auto p-2">
      <p
        class="m-4 text-4xl font-bold text-gfx-primary dark:text-gfx-accent text-center"
      >
        Share
      </p>
      <div
        class="border-2 border-solid border-gfx-primary dark:border-gfx-accent rounded-xl mx-auto p-4 shadow-lg"
      >
        <p class="text-center dark:text-zinc-300">
          Keeping your gym members up to date with your class just got a lot
          easier. You've already done the work planning your class. Leave it to
          GFX to let your members know what's in store.
        </p>
        <img
          class="dark:hidden mx-auto w-auto p-2 rounded-3xl"
          src="../../assets/images/gfx-share-light.webp"
        />
        <img
          class="hidden dark:inline mx-auto w-auto p-2 rounded-3xl"
          src="../../assets/images/gfx-share-dark.webp"
        />
      </div>
    </section>
    <div class="mt-8 max-w-sm mx-auto">
      <img
        class="dark:hidden"
        src="../../assets/images/MS_Startups_Celebration_Badge_Light.webp"
        alt="Partner with Microsoft for Startups"
      />
      <img
        class="hidden dark:inline"
        src="../../assets/images/MS_Startups_Celebration_Badge_Dark.webp"
        alt="Partner with Microsoft for Startups"
      />
    </div>
  </div>
</div>
